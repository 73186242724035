import ApolloClient, { fromPromise } from 'apollo-boost';
import 'isomorphic-unfetch';

import env from './environments';
import { LOCAL_LOGOUT, REFRESH_TOKEN, LOCAL_LOGIN } from './mutations';
import resolvers from './resolvers';
import safeLocalStorage from './safeLocalStorage';
import safeSessionStorage from './safeSessionStorage';

const client = new ApolloClient({
  uri: env.URI,
  request: (operation) => {
    const token = safeLocalStorage.getItem('token');
    const isSidebarOpen = safeLocalStorage.getItem('isSidebarOpen');
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        isSidebarOpen,
      },
    });
  },
  onError: ({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
      const promises = graphQLErrors.map(async ({ message }: any) => {
        // TODO: Move to a function
        const token = safeSessionStorage.getItem('token');
        if (message.statusCode === 401) {
          if (token) {
            try {
              const response = await client.mutate({
                mutation: REFRESH_TOKEN,
                variables: { token },
              });
              await client.mutate({
                mutation: LOCAL_LOGIN,
                variables: response?.data?.refreshAccessToken,
              });

              return true;
            } catch (error) {
              client.mutate({
                mutation: LOCAL_LOGOUT,
              });
            }
          } else {
            client.mutate({
              mutation: LOCAL_LOGOUT,
            });
          }
        }
      });

      return fromPromise(Promise.all(promises))
        .filter((value) => Boolean(value))
        .flatMap(() => forward(operation));
    }
  },
  resolvers,
});

export default client;
